/** @jsx jsx */
import Slider, { Settings as SlickSettings } from 'react-slick'
import { jsx } from '@emotion/react'
import { View, Text, StyleSheet, CenterWrapper, Image, onMount, useState, Touchable } from 'lib'
import { Theme, TextStyles, Settings } from 'app'
import screenshot1 from '../../assets/screenshot1.png'
import { AppStore, PlayStore } from '../../assets/StoreAssets'
import '_app/stylesheets/css/slick.css'
import '_app/stylesheets/css/slick-theme.css'
import BgImage1 from 'assets/landing-bg/3-woman-trendy-clothes.jpg'
import BgImage2 from 'assets/landing-bg/2-man-walking.jpg'
import BgImage3 from 'assets/landing-bg/1-woman-london.jpg'
import BgImage4 from 'assets/landing-bg/4-uk-muslim-woman.jpg'
import BgImage5 from 'assets/landing-bg/5-happy-man.jpg'
import BgImage6 from 'assets/landing-bg/6-backpack-woman.jpg'
import BgImage7 from 'assets/landing-bg/7-wheelchair.jpg'
import BgImage8 from 'assets/landing-bg/8-map.jpg'
import BgImage9 from 'assets/landing-bg/9-woman-running.png'
import BgImage10 from 'assets/landing-bg/10-woman-phone.png'
import BgImage11 from 'assets/landing-bg/11-man-bike.png'
import BgImage12 from 'assets/landing-bg/12-girl-running.png'

import ImagePreloader from 'image-preloader'
import FacebookEvents from 'actions/modules/FacebookEvents'

const heading = ['Peace Of Mind Is', 'A Powerful Thing']
const subheading = 'Becon is the smart safety app that privately protects your walks, runs, cycles & more.'

const settings: SlickSettings = {
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 7000,
}

const bgImages = [
  { image: BgImage8, title: 'Map' },
  { image: BgImage10, title: 'Woman in phone' },
  { image: BgImage12, title: 'Girl running', mobileStyle: { objectPosition: '60% 0' } },
  { image: BgImage11, title: 'Man biking', mobileStyle: { objectPosition: '40% 0' } },
  { image: BgImage1, title: 'Woman walking', mobileStyle: { objectPosition: '42% 0' } },
  { image: BgImage9, title: 'Woman running', mobileStyle: { objectPosition: '18% 0' } },
  { image: BgImage2, title: 'Man creating a route on Becon' },
  { image: BgImage3, title: 'Woman safely walking in London', mobileStyle: { objectPosition: '58% 0' } },
  { image: BgImage4, title: 'Muslim woman in a Becon route' },
  { image: BgImage5, title: 'Happy man walking', mobileStyle: { objectPosition: '60% 0' } },
  { image: BgImage6, title: 'Woman safely walking' },
  { image: BgImage7, title: 'Woman in wheelchair' },
]

function Landing() {
  const isMobile = Theme.hooks.down('mid')
  const isTinyest = Theme.hooks.down('tinyest')
  const [currentSlide, setCurrentSlide] = useState(0)

  onMount(() => {
    const preloader = new ImagePreloader()
    preloader.preload(bgImages.map(i => i.image))
      .then(function(status) {
        log('Landing images loaded', status)
      })
  })

  const showSampleDevice = isMobile ? currentSlide === 0 : true

  const beforeChange = (args) => {
    const next = args + 1
    const nextSlide = next > bgImages.length - 1 ? 0 : next
    setCurrentSlide(nextSlide)
  }

  return (
    <View variant='flex column' style={styles.innerWrapper} id='#home'>
      <View style={styles.imagesWrapper} id='#download'>
        <View style={[styles.shadow, showSampleDevice && styles.shadowDark]}/>
        <Slider {...settings} beforeChange={beforeChange}>
          {bgImages.map(({ image, title, mobileStyle = {} }, key) => (
            <View key={key} style={styles.imageWrapper}>
              <Image source={image} alt={title} style={isMobile ? { ...styles.image, ...mobileStyle } : styles.image} rel='preload' as='image'/>
            </View>
          ))}
        </Slider>
        <View style={styles.gradient}/>
      </View>
      <CenterWrapper
        style={[styles.centerWrapper]}
        contentContainerStyle={[styles.contentContainer]}
      >
        <View style={[styles.textWrapper]}>
          <View style={[styles.headerTextContainer]} variants={['flex', 'column']}>
            {heading.map((text, key) => (
              <Text
                key={key}
                variant={['h1', 'white', 'flex', isMobile && 'textCenter']}
                style={[
                  styles.headerText,
                  isMobile && styles.headerTextMobile,
                  isTinyest && styles.headerTextTyniest,
                ]}>
                {text}
              </Text>
            ))}
          </View>
          <Text variant={['p1', 'white', 'flex', 'marginTop:3', 'marginBottom:3']} style={[styles.paragraphText, isMobile && { display: 'flex', textAlign: 'center' }]}>
            {subheading}
          </Text>

          <View variant={['flexNoFill', 'marginTop:3', 'marginBottom:3']} style={styles.buttonWrapper}>
            <Touchable onPress={() => FacebookEvents.LeadEvent('appStore')}>
              <a href={Settings.APP_STORE_LINK} target='_blank' rel='noopener, noreferrer'>
                <AppStore/>
                <Text style={styles.button}>Download Becon on App Store</Text>
              </a>
            </Touchable>
            <Touchable onPress={() => FacebookEvents.LeadEvent('playStore')}>
              <a href={Settings.GOOGLE_PLAY_LINK} target='_blank' rel='noopener, noreferrer'>
                <PlayStore/>
                <Text style={styles.button}>Download Becon on Google Play</Text>
              </a>
            </Touchable>
          </View>
        </View>
        <View style={[styles.staticImageWrapper, currentSlide === 0 ? styles.staticImageWrapperVisible : styles.staticImageWrapperOut]}>
          <Image
            source={screenshot1}
            alt='Becon app route'
            style={styles.screenshotWrapper}
          />
        </View>
      </CenterWrapper>
    </View>
  )
}

const transition = 'all 0.5s ease-in-out 0.2s'
const transition2 = 'all 1s ease-in-out 0.4s'

const browserNavBarHeight = '108' // Totally arbitrary number, maybe should be in theme

const styles = StyleSheet.createComponentStyleSheet({
  innerWrapper: {
    position: 'relative',
    minHeight: '100vh',
    ...Theme.flex,
    ...Theme.fullWidth,
    [Theme.media.down('mid')]: {
      minHeight: `max(calc(100vh - ${Theme.values.maxHeaderMenuHeight}px), 600px)`,
      maxHeight: `calc(100vh - ${Theme.values.maxHeaderMenuHeight}px)`,
    },
    [Theme.media.down('tiny')]: {
      minHeight: `max(calc(100vh + ${browserNavBarHeight}px), 600px)`,
      maxHeight: `calc(100vh + ${browserNavBarHeight}px)`,
    },
  },
  imagesWrapper: {
    ...Theme.absolute,
    ...Theme.whole,
    zIndex: -1,
    '.slick-list': {
      width: '100%',
    },
  },
  image: {
    objectFit: 'cover',
    width: '100%',
    minHeight: '100vh',
  },
  imageWrapper: {
    ...Theme.absolute,
    ...Theme.whole,
    zIndex: -2,
  },
  shadow: {
    position: 'absolute',
    inset: 0,
    backgroundColor: 'rgb(0, 0, 0, 0.3)',
    zIndex: 99,
    transition,
  },
  shadowDark: {
    backgroundColor: 'rgb(0, 0, 0, 0.6)',
    transition,
  },
  centerWrapper: {
    alignItems: 'flex-end',
    ...Theme.flex,
  },
  contentContainer: {
    ...Theme.flex,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '8vh',
    [Theme.media.down('mid')]: {
      marginBottom: 0,
      flexDirection: 'column-reverse',
    },
  },
  textWrapper: {
    width: '100%',
    [Theme.media.down('mid')]: {
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      transition: transition2,
      transform: `translateY(0) scale(0.95)`,
    },
  },
  textWrapperUp: {
    [Theme.media.down('mid')]: {
      transform: `translateY(-${Theme.spacing(4)}px) scale(1)`,
    },
    transition: transition2,
  },
  headerTextContainer: {
    width: 'max-content',
  },
  headerText: {
    lineHeight: '1.2',
  },
  headerTextMobile: {
    letterSpacing: '-6',
    lineHeight: '1.6',
  },
  headerTextTyniest: {
    ...TextStyles.textObject.h2,
    letterSpacing: '-6',
    textAlign: 'center',
  },
  paragraphText: {
    maxWidth: 600,
  },
  screenshotWrapper: {
    width: '60%',
    maxHeight: '80vh',
    objectFit: 'contain',
    [Theme.media.down('mid')]: {
      maxHeight: '45vh',
    },
  },
  screenshotWrapperMobile: {
    width: '60%',
    objectFit: 'contain',
  },
  staticImageWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    [Theme.media.down('mid')]: {
      justifyContent: 'center',
    },
  },
  staticImageWrapperVisible: {
    [Theme.media.down('mid')]: {
      opacity: 1,
      transition,
      transform: 'translateY(0)',
    },
  },
  staticImageWrapperOut: {
    [Theme.media.down('mid')]: {
      opacity: 0,
      transition,
      transform: 'translateY(5vh)',
    },
  },
  buttonWrapper: {
    display: 'flex',
    gap: Theme.spacing(2),
    [Theme.media.down('tiny')]: {
      marginTop: Theme.spacing(2),
      flexDirection: 'column',
      ...Theme.center,
    },
  },
  button: {
    display: 'none',
  },
  gradient: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    height: '30vh',
    background: `linear-gradient(0deg, ${Theme.colors.greyscale1} 0%, transparent 100%);`,
    [Theme.media.down('mid')]: {
      height: '50vh',
      background: `linear-gradient(0deg, ${Theme.colors.greyscale1} 0%, ${Theme.colors.greyscale1} 30%, transparent 100%);`,
    },
    zIndex: 100,
  },
})

export default Landing
